import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './services/user.service';
import { UserModel } from './models/user.model';

@Component({
  selector: 'app-loop-back',
  styleUrls: ['./loop-back.component.css'],
  template: `

  <div id="id01" class="modal">
    <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()" class="modal-content animate">
    <span onclick="document.getElementById('id01').style.display='none'" class="close" title="Close Modal">&times;</span>

  <div class="container">

    <input #box (keyup)="0">
    <p>{{box.value}}</p>

  
  <label for="question"><b>Zapytanie</b></label>
  <textarea class="form-control rounded-0" id="question" 
    formControlName="question" type="text" placeholder="Wpisz pytanie" 
    name="question" required rows="10"></textarea>

  <label for="uname"><b>Username</b></label>
  <input id="name" formControlName="name" type="text" placeholder="Enter Username" name="uname" required>

  <label for="psw"><b>Password</b></label>
  <input formControlName="password" type="password" placeholder="Enter Password" name="psw" required>

  <label for="accept"><b>Password</b></label>
  <input type="checkbox" formControlName="accept" name="accept" />

  <button type="submit">Login</button>
</div>

</form>
</div>
  `
})

export class LoopbackComponent {

  constructor(private userService: UserService) { }

  userForm = new FormGroup({
    question: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required, Validators.minLength(6)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    accept: new FormControl('', [Validators.required, Validators.minLength(6)])
  }); 

  onFormSubmit() {
    if (this.userForm.valid) {

      let user: UserModel = new UserModel();
      user.name = this.userForm.value.name;
      user.password = this.userForm.value.password;
      this.userService.putUser(user);

      console.log("Form Submitted! -->" + this.userForm.value.password + "<--");
      this.userForm.reset();
    }
  }
 }