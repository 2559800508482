import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders } from '@angular/common/http';
import { UserModel } from '../models/user.model';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  putUser(user: UserModel){
    //let headers =  {headers: new  HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})};
    let headers = {headers: new  HttpHeaders({ 'Content-Type': 'application/json'})};
  
    //let url = `${this.heroesUrl}/${hero.id}`;
    let url = `http://milum.pl/put_user`;
  
    return this.http
        .post(url, JSON.stringify(user), 
          {headers: new  HttpHeaders({ 'Content-Type': 'application/json'})})
          //.map((res:any) => res.json())
          .map((res:any) => res)
            .subscribe(
                data => this.saveJwt(data),
                err => this.logError(err),
                () =>this. done()
            );
//        .map(res => res.json());
  }

  done() {
    console.log("WK_DONE");

  }
  
  saveJwt(jwt: string) {
    console.log("WK_JWT");
    //if(jwt) localStorage.setItem('id_token', jwt)
  }

  logError(err: any) {
    console.log("WK_ERROR ");
    console.log(err);
}

  /*
  getProducts(category_id: number):Observable<ProductModel[]> {
    return this.http.get('/api/1/cp_' + String(category_id) + '.json')
        .map(this.extractData)
        .catch(this.handleError);
  }*/

  private handleError(error:any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
 }

  private extractData(res:Response) {
    let body = res;
    return body || [];
  }

}
