import { Component } from '@angular/core';
import { LoopbackComponent } from './loop-back.component';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'description', content: 'MAGNAXIS, oh yeah!' });
    this.meta.addTag({ name: 'author', content: 'MAGNAXIS' });
    this.meta.addTag({ name: 'keywords', content: 'Login Magnaxis' });
  }
}
